import React, { useState, useEffect, forwardRef } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import DateRange from "@mui/icons-material/DateRange";
import AccessTime from "@mui/icons-material/AccessTime";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Loader from "components/Loader/Loader";

import { dailySalesChart } from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [pendientes, setPendientes] = useState();
  const [pendientesDias, setPendientesDias] = useState([]);
  const [pendientesNombreDias, setPendientesNombreDias] = useState([]);
  const [pendientesOptions, setPendientesOptions] = useState({...dailySalesChart.options});

  const [procesadas, setProcesadas] = useState();
  const [procesadasDias, setProcesadasDias] = useState([]);
  const [procesadasNombreDias, setProcesadasNombreDias] = useState([]);
  const [procesadasOptions, setProcesadasOptions] = useState({...dailySalesChart.options});

  const [aprobadas, setAprobadas] = useState();
  const [aprobadasDias, setAprobadasDias] = useState([]);
  const [aprobadasNombreDias, setAprobadasNombreDias] = useState([]);
  const [aprobadasOptions, setAprobadasOptions] = useState({...dailySalesChart.options});

  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [load, setLoad] = useState(false);

  const [promedioAutenticacion, setPromedioAutenticacion] = useState(0);
  const [promedioAprobacion, setPromedioAprobacion] = useState(0);

  const [reportePendientes, setReportePendientes] = useState([]);
  const [reporteProcesadas, setReporteProcesadas] = useState([]);
  const [reporteAprobadas, setReporteAprobadas] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const sendSnackBar = (pmessage, pcolor) => {
    setOpenSnackbar(true);
    setColor(pcolor);
    setMessage(pmessage);
  };

  const convertToCSV = (objArray) => {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
        let line = "";

        for (let index in array[i]) {
            if (line !== "") line += ",";
            line += array[i][index];
        }

        str += line + "\r\n";
    }

    return str;
  };

  const exportCSVFile = (headers, items, fileName) => {
    if (headers) {
        items.unshift(headers);
    }

    const jsonObject = JSON.stringify(items);
    const csv = convertToCSV(jsonObject);
    const exportName = fileName + ".csv" || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportName);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  };

  const exportaPendientes = (JSONData) => {
    if(GetIdRol() === 1){
      let arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
      let reportTitle = "Reporte_Pendientes";
      let headers = {};

      arrData = arrData.map(item => { 
        return { 
          Referencia: "'" + item.Referencia.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaInvex: "'" + item.ReferenciaInvex.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Nombres: item.Nombres.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ApellidoPaterno: item.ApellidoPaterno.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ApellidoMaterno: item.ApellidoMaterno.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaNacimiento: item.FechaNacimiento.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          RFC: item.RFC.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Correo: item.Correo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Celular: item.Celular.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          TelefonoFijo: item.TelefonoFijo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          TelefonoTrabajo: item.TelefonoTrabajo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          EstadoCivil: item.EstadoCivil.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          GradoEstudios: item.GradoEstudios.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          LugarNacimiento: item.LugarNacimiento.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Calle: item.Calle.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          NumeroExterior: item.NumeroExterior.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          NumeroInterior: item.NumeroInterior.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Colonia: item.Colonia.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Estado: item.Estado.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Localidad: item.Localidad.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          CodigoPostal: item.CodigoPostal.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Banco: item.Banco.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaTDC: item.ReferenciaTDC.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaCreditoHipotecario: item.ReferenciaCreditoHipotecario.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaCreditoAuto: item.ReferenciaCreditoAuto.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Producto: item.Producto.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Capturista: item.Capturista.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Validador: item.Validador.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          IngresoMensual: item.IngresoMensual,
          Estatus: item.Estatus.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          TipoBase: item.TipoBase.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaCaptura: item.FechaCaptura.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaSanitizacion: item.FechaSanitizacion.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FolioFico: item.FolioFico.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Pendiente: item.Pendiente.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Comentarios: item.Comentarios.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ActividadLaboral: item.ActividadLaboral.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          CodigoPostalLaboral: item.CodigoPostalLaboral.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          DependientesEconomicos: item.DependientesEconomicos.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaCapturista: item.FechaCapturista.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaValidador: item.FechaValidador.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "")
        };
      });

      headers = {
          Referencia: "Referencia",
          ReferenciaInvex: "Referencia Invex",
          Nombres: "Nombres",
          ApellidoPaterno: "Apellido Paterno",
          ApellidoMaterno: "Apellido Materno",
          FechaNacimiento: "Fecha Nacimiento",
          RFC: "RFC",
          Correo: "Correo",
          Celular: "Celular",
          TelefonoFijo: "Telefono Fijo",
          TelefonoTrabajo: "Telefono Trabajo",
          EstadoCivil: "Estado Civil",
          GradoEstudios: "Grado Estudios",
          LugarNacimiento: "Lugar Nacimiento",
          Calle: "Calle",
          NumeroExterior: "Numero Exterior",
          NumeroInterior: "Numero Interior",
          Colonia: "Colonia",
          Estado: "Estado",
          Localidad: "Localidad",
          CodigoPostal: "Codigo Postal",
          Banco: "Banco",
          ReferenciaTDC: "Referencia TDC",
          ReferenciaCreditoHipotecario: "Referencia Credito Hipotecario",
          ReferenciaCreditoAuto: "Referencia Credito Auto",
          Producto: "Producto",
          Capturista: "Capturista",
          Validador: "Validador",
          IngresoMensual: "Ingreso Mensual",
          Estatus: "Estatus",
          TipoBase: "TipoBase",
          FechaCaptura: "FechaCaptura",
          FechaSanitizacion: "FechaSanitizacion",
          FolioFico: "FolioFico",
          Pendiente: "Pendiente",
          Comentarios: "Comentarios",
          ActividadLaboral: "ActividadLaboral",
          CodigoPostalLaboral: "CodigoPostalLaboral",
          DependientesEconomicos: "DependientesEconomicos",
          FechaCapturista: "FechaCapturista",
          FechaValidador: "FechaValidador"
      };
      
      exportCSVFile(headers, arrData, reportTitle);
    }
  };

  const exportaProcesadas = (JSONData) => {
    if(GetIdRol() === 1){
      let arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
      let reportTitle = "Reporte_Procesadas";
      let headers = {};

      arrData = arrData.map(item => { 
        return { 
          Referencia: "'" + item.Referencia.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaInvex: "'" + item.ReferenciaInvex.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Nombres: item.Nombres.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ApellidoPaterno: item.ApellidoPaterno.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ApellidoMaterno: item.ApellidoMaterno.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaNacimiento: item.FechaNacimiento.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          RFC: item.RFC.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Correo: item.Correo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Celular: item.Celular.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          TelefonoFijo: item.TelefonoFijo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          TelefonoTrabajo: item.TelefonoTrabajo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          EstadoCivil: item.EstadoCivil.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          GradoEstudios: item.GradoEstudios.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          LugarNacimiento: item.LugarNacimiento.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Calle: item.Calle.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          NumeroExterior: item.NumeroExterior.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          NumeroInterior: item.NumeroInterior.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Colonia: item.Colonia.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Estado: item.Estado.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Localidad: item.Localidad.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          CodigoPostal: item.CodigoPostal.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Banco: item.Banco.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaTDC: item.ReferenciaTDC.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaCreditoHipotecario: item.ReferenciaCreditoHipotecario.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaCreditoAuto: item.ReferenciaCreditoAuto.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Producto: item.Producto.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Capturista: item.Capturista.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Validador: item.Validador.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          IngresoMensual: item.IngresoMensual,
          Estatus: item.Estatus.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          TipoBase: item.TipoBase.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaCaptura: item.FechaCaptura.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaSanitizacion: item.FechaSanitizacion.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FolioFico: item.FolioFico.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Pendiente: item.Pendiente.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Comentarios: item.Comentarios.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ActividadLaboral: item.ActividadLaboral.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          CodigoPostalLaboral: item.CodigoPostalLaboral.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          DependientesEconomicos: item.DependientesEconomicos.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaCapturista: item.FechaCapturista.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaValidador: item.FechaValidador.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Adicional: item.Adicional.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Asistencia: item.Asistencia.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "")
        };
      });

      headers = {
          Referencia: "Referencia",
          ReferenciaInvex: "Referencia Invex",
          Nombres: "Nombres",
          ApellidoPaterno: "Apellido Paterno",
          ApellidoMaterno: "Apellido Materno",
          FechaNacimiento: "Fecha Nacimiento",
          RFC: "RFC",
          Correo: "Correo",
          Celular: "Celular",
          TelefonoFijo: "Telefono Fijo",
          TelefonoTrabajo: "Telefono Trabajo",
          EstadoCivil: "Estado Civil",
          GradoEstudios: "Grado Estudios",
          LugarNacimiento: "Lugar Nacimiento",
          Calle: "Calle",
          NumeroExterior: "Numero Exterior",
          NumeroInterior: "Numero Interior",
          Colonia: "Colonia",
          Estado: "Estado",
          Localidad: "Localidad",
          CodigoPostal: "Codigo Postal",
          Banco: "Banco",
          ReferenciaTDC: "Referencia TDC",
          ReferenciaCreditoHipotecario: "Referencia Credito Hipotecario",
          ReferenciaCreditoAuto: "Referencia Credito Auto",
          Producto: "Producto",
          Capturista: "Capturista",
          Validador: "Validador",
          IngresoMensual: "Ingreso Mensual",
          Estatus: "Estatus",
          TipoBase: "TipoBase",
          FechaCaptura: "FechaCaptura",
          FechaSanitizacion: "FechaSanitizacion",
          FolioFico: "FolioFico",
          Pendiente: "Pendiente",
          Comentarios: "Comentarios",
          ActividadLaboral: "ActividadLaboral",
          CodigoPostalLaboral: "CodigoPostalLaboral",
          DependientesEconomicos: "DependientesEconomicos",
          FechaCapturista: "FechaCapturista",
          FechaValidador: "FechaValidador",
          Adicional: "Adicional",
          Asistencia: "Asistencia"
      };
      
      exportCSVFile(headers, arrData, reportTitle);
    }
  };

  const exportaAprobadas = (JSONData) => {
    if(GetIdRol() === 1){
      let arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
      let reportTitle = "Reporte_Aprobadas";
      let headers = {};

      arrData = arrData.map(item => { 
        return { 
          Referencia: "'" + item.Referencia.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaInvex: "'" + item.ReferenciaInvex.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Nombres: item.Nombres.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ApellidoPaterno: item.ApellidoPaterno.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ApellidoMaterno: item.ApellidoMaterno.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaNacimiento: item.FechaNacimiento.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          RFC: item.RFC.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Correo: item.Correo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Celular: item.Celular.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          TelefonoFijo: item.TelefonoFijo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          TelefonoTrabajo: item.TelefonoTrabajo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          EstadoCivil: item.EstadoCivil.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          GradoEstudios: item.GradoEstudios.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          LugarNacimiento: item.LugarNacimiento.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Calle: item.Calle.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          NumeroExterior: item.NumeroExterior.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          NumeroInterior: item.NumeroInterior.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Colonia: item.Colonia.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Estado: item.Estado.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Localidad: item.Localidad.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          CodigoPostal: item.CodigoPostal.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Banco: item.Banco.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaTDC: item.ReferenciaTDC.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaCreditoHipotecario: item.ReferenciaCreditoHipotecario.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ReferenciaCreditoAuto: item.ReferenciaCreditoAuto.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Producto: item.Producto.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Capturista: item.Capturista.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Validador: item.Validador.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          IngresoMensual: item.IngresoMensual,
          Estatus: item.Estatus.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          TipoBase: item.TipoBase.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaCaptura: item.FechaCaptura.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaSanitizacion: item.FechaSanitizacion.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FolioFico: item.FolioFico.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Pendiente: item.Pendiente.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          Comentarios: item.Comentarios.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          ActividadLaboral: item.ActividadLaboral.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          CodigoPostalLaboral: item.CodigoPostalLaboral.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          DependientesEconomicos: item.DependientesEconomicos.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaCapturista: item.FechaCapturista.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
          FechaValidador: item.FechaValidador.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "")
        };
      });

      headers = {
          Referencia: "Referencia",
          ReferenciaInvex: "Referencia Invex",
          Nombres: "Nombres",
          ApellidoPaterno: "Apellido Paterno",
          ApellidoMaterno: "Apellido Materno",
          FechaNacimiento: "Fecha Nacimiento",
          RFC: "RFC",
          Correo: "Correo",
          Celular: "Celular",
          TelefonoFijo: "Telefono Fijo",
          TelefonoTrabajo: "Telefono Trabajo",
          EstadoCivil: "Estado Civil",
          GradoEstudios: "Grado Estudios",
          LugarNacimiento: "Lugar Nacimiento",
          Calle: "Calle",
          NumeroExterior: "Numero Exterior",
          NumeroInterior: "Numero Interior",
          Colonia: "Colonia",
          Estado: "Estado",
          Localidad: "Localidad",
          CodigoPostal: "Codigo Postal",
          Banco: "Banco",
          ReferenciaTDC: "Referencia TDC",
          ReferenciaCreditoHipotecario: "Referencia Credito Hipotecario",
          ReferenciaCreditoAuto: "Referencia Credito Auto",
          Producto: "Producto",
          Capturista: "Capturista",
          Validador: "Validador",
          IngresoMensual: "Ingreso Mensual",
          Estatus: "Estatus",
          TipoBase: "TipoBase",
          FechaCaptura: "FechaCaptura",
          FechaSanitizacion: "FechaSanitizacion",
          FolioFico: "FolioFico",
          Pendiente: "Pendiente",
          Comentarios: "Comentarios",
          ActividadLaboral: "ActividadLaboral",
          CodigoPostalLaboral: "CodigoPostalLaboral",
          DependientesEconomicos: "DependientesEconomicos",
          FechaCapturista: "FechaCapturista",
          FechaValidador: "FechaValidador"
      };
      
      exportCSVFile(headers, arrData, reportTitle);
    }
  };

  const Login = async () => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
            headers: {
                "Content-Type": "application/json"
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Login",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const GetIdSucursal = () => {
    return JSON.parse(localStorage.getItem('userCEX')).IdSucursales;
  };

  const GetIdRol = () => {
    return JSON.parse(localStorage.getItem('userCEX')).idRol;
  };

  const ReportePendientes = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ReportePendientes",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ReporteProcesadas = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ReporteProcesadas",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ReporteAprobadas = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ReporteAprobadas",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const PromedioAutenticacion = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/PromedioAutenticacion",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const PromedioAprobacion = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/PromedioAprobacion",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  useEffect(() => {
    try{
      setLoad(true);

      let hoy = moment(Date.now());
      hoy.set({h: 0, m: 0, s: 0});

      let selectFechaInicio = moment(Date.now());
      selectFechaInicio.set({h: 0, m: 0, s: 0});
      selectFechaInicio.add(-8, 'd');

      let selectFechaFin = moment(Date.now());
      selectFechaFin.set({h: 23, m: 59, s: 59});

      let reportes = {
        FechaInicio: selectFechaInicio.format("YYYY/MM/DD HH:mm:ss"),
        FechaFin: selectFechaFin.format("YYYY/MM/DD HH:mm:ss"),
        IdSucursales: GetIdSucursal()
      };

      let promedios = {
        FechaInicio: hoy.format("YYYY/MM/DD HH:mm:ss"),
        FechaFin: selectFechaFin.format("YYYY/MM/DD HH:mm:ss"),
        IdSucursales: GetIdSucursal()
      };

      Login()
      .then(result => {
          if (result !== undefined) {
              ReportePendientes(reportes, result.token)
              .then(result => {
                let capturas = result.capturas.map((element) => {
                  element.FechaNacimiento = element.FechaNacimiento === "" ? "" : moment(moment(element.FechaNacimiento, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaCaptura = element.FechaCaptura === "" ? "" : moment(moment(element.FechaCaptura, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaHigienizacion = element.FechaHigienizacion === "" ? "" : moment(moment(element.FechaHigienizacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaValidador = element.FechaValidador === "" ? "" : moment(moment(element.FechaValidador, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.ReferenciaCreditoHipotecario = element.ReferenciaCreditoHipotecario === "1" ? "Si" : "No";
                  element.ReferenciaCreditoAuto = element.ReferenciaCreditoAuto === "1" ? "Si" : "No";
                  element.Pendiente = element.Pendiente === "1" ? "Si" : "No";
                  return element;
                });

                let dia = 0;
                let nombreDias = [];
                let dias = [];
                while (dia <= 7) {
                  let fecha = moment(Date.now());
                  fecha.set({h: 0, m: 0, s: 0});
                  fecha.add(-dia, 'd');
                  let nombreDia = fecha.format('dddd').substring(0,2).toUpperCase();

                  if(nombreDia !== 'DO'){
                    let registros = 0;
                    capturas.forEach(item => {
                      if(moment(item.FechaValidador, "DD-MM-YYYY").isSame(fecha.toDate(), 'day')){
                        registros = registros + 1;
                      }
                    });

                    dias.push(registros);
                    nombreDias.push(nombreDia);
                  }

                  dia = dia + 1;
                }

                let cap = {...pendientesOptions};
                cap.high = Math.max(...dias) + 10;

                setPendientesOptions(cap);

                setPendientesDias(dias.reverse());
                setPendientesNombreDias(nombreDias.reverse());
                
                let reporte = capturas.filter(item => moment(item.FechaValidador, "DD-MM-YYYY").isSameOrAfter(hoy.toDate(), 'day'));
                setPendientes(reporte.length);
                setReportePendientes(reporte);
              });

              ReporteProcesadas(reportes, result.token)
              .then(result => {
                let capturas = result.capturas.map((element) => {
                  element.FechaNacimiento = element.FechaNacimiento === "" ? "" : moment(moment(element.FechaNacimiento, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaCaptura = element.FechaCaptura === "" ? "" : moment(moment(element.FechaCaptura, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaHigienizacion = element.FechaHigienizacion === "" ? "" : moment(moment(element.FechaHigienizacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaValidador = element.FechaValidador === "" ? "" : moment(moment(element.FechaValidador, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.ReferenciaCreditoHipotecario = element.ReferenciaCreditoHipotecario === "1" ? "Si" : "No";
                  element.ReferenciaCreditoAuto = element.ReferenciaCreditoAuto === "1" ? "Si" : "No";
                  element.Pendiente = element.Pendiente === "1" ? "Si" : "No";
                  element.Adicional = element.Adicional ? "Si" : "No";
                  element.Asistencia = element.Asistencia ? "Si" : "No";

                  return element;
                });

                let dia = 0;
                let nombreDias = [];
                let dias = [];
                while (dia <= 7) {
                  let fecha = moment(Date.now());
                  fecha.set({h: 0, m: 0, s: 0});
                  fecha.add(-dia, 'd');
                  let nombreDia = fecha.format('dddd').substring(0,2).toUpperCase();

                  if(nombreDia !== 'DO') {
                    let registros = 0;
                    capturas.forEach(item => {
                      if(moment(item.FechaValidador, "DD-MM-YYYY").isSame(fecha.toDate(), 'day')){
                        registros = registros + 1;
                      }
                    });

                    dias.push(registros);
                    nombreDias.push(nombreDia);
                  }

                  dia = dia + 1;
                }

                let cap = {...procesadasOptions};
                cap.high = Math.max(...dias) + 10;

                setProcesadasOptions(cap);

                setProcesadasDias(dias.reverse());
                setProcesadasNombreDias(nombreDias.reverse());
                
                let reporte = capturas.filter(item => moment(item.FechaValidador, "DD-MM-YYYY").isSameOrAfter(hoy.toDate(), 'day'));
                setProcesadas(reporte.length);
                setReporteProcesadas(reporte);
                setLoad(false);
              });

              ReporteAprobadas(reportes, result.token)
              .then(result => {
                let capturas = result.capturas.map((element) => {
                  element.FechaNacimiento = element.FechaNacimiento === "" ? "" : moment(moment(element.FechaNacimiento, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaCaptura = element.FechaCaptura === "" ? "" : moment(moment(element.FechaCaptura, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaHigienizacion = element.FechaHigienizacion === "" ? "" : moment(moment(element.FechaHigienizacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.FechaValidador = element.FechaValidador === "" ? "" : moment(moment(element.FechaValidador, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                  element.ReferenciaCreditoHipotecario = element.ReferenciaCreditoHipotecario === "1" ? "Si" : "No";
                  element.ReferenciaCreditoAuto = element.ReferenciaCreditoAuto === "1" ? "Si" : "No";
                  element.Pendiente = element.Pendiente === "1" ? "Si" : "No";
                  return element;
                });

                let dia = 0;
                let nombreDias = [];
                let dias = [];
                while (dia <= 7) {
                  let fecha = moment(Date.now());
                  fecha.set({h: 0, m: 0, s: 0});
                  fecha.add(-dia, 'd');
                  let nombreDia = fecha.format('dddd').substring(0,2).toUpperCase();

                  if(nombreDia !== 'DO') {
                    let registros = 0;
                    capturas.forEach(item => {
                      if(moment(item.FechaValidador, "DD-MM-YYYY").isSame(fecha.toDate(), 'day')){
                        registros = registros + 1;
                      }
                    });

                    dias.push(registros);
                    nombreDias.push(nombreDia);
                  }

                  dia = dia + 1;
                }

                let cap = {...aprobadasOptions};
                cap.high = Math.max(...dias) + 10;

                setAprobadasOptions(cap);

                setAprobadasDias(dias.reverse());
                setAprobadasNombreDias(nombreDias.reverse());
                
                let reporte = capturas.filter(item => moment(item.FechaValidador, "DD-MM-YYYY").isSameOrAfter(hoy.toDate(), 'day'));
                setAprobadas(reporte.length);
                setReporteAprobadas(reporte);
              });

              PromedioAutenticacion(promedios, result.token)
              .then(result => {
                setPromedioAutenticacion(parseInt(result.promedio.Promedio));
              });

              PromedioAprobacion(promedios, result.token)
              .then(result => {
                setPromedioAprobacion(parseInt(result.promedio.Promedio));
              });
          } else {
              sendSnackBar("Error de conexión", "error");
              setLoad(false);
          }
      }).catch(error => sendSnackBar("Error de conexión", "error"));
    } catch(error){
        sendSnackBar("Error de conexión", "error");
        setLoad(false);
    }
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader stats icon>
              <p className={classes.cardCategory}>Promedio Autenticación</p>
              <h3 className={classes.cardTitle}>{promedioAutenticacion + "%"}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Últimas 24 Horas
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader stats icon>
              <p className={classes.cardCategory}>Promedio Aprobación</p>
              <h3 className={classes.cardTitle}>{promedioAprobacion + "%"}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Últimas 24 Horas
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="rose" stats icon>
              <CardIcon color="rose">
                <Icon>
                  <a
                    href="#"
                    className={classes.anchor}
                    onClick={e => {
                      exportaAprobadas(reporteAprobadas);
                    }}
                  >
                    check
                  </a>
                </Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Aprobadas</p>
              <h3 className={classes.cardTitle}>{aprobadas}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Últimas 24 Horas
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card chart>
            <CardHeader color="rose">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: aprobadasNombreDias,
                  series: [aprobadasDias]
                }}
                type="Line"
                options={aprobadasOptions}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}></h4>
              <p className={classes.cardCategory}></p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Última semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <Icon>
                  <a
                    href="#"
                    className={classes.anchor}
                    onClick={e => {
                      exportaProcesadas(reporteProcesadas);
                    }}
                  >
                    add_task_icon
                  </a>
                </Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Procesadas</p>
              <h3 className={classes.cardTitle}>{procesadas}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Últimas 24 Horas
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card chart>
            <CardHeader color="primary">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: procesadasNombreDias,
                  series: [procesadasDias]
                }}
                type="Line"
                options={procesadasOptions}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}></h4>
              <p className={classes.cardCategory}></p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Última semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>
                  <a
                    href="#"
                    className={classes.anchor}
                    onClick={e => {
                      exportaPendientes(reportePendientes);
                    }}
                  >
                    developer_board
                  </a>
                </Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Pendientes</p>
              <h3 className={classes.cardTitle}>{pendientes}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Últimas 24 Horas
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card chart>
            <CardHeader color="info">
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: pendientesNombreDias,
                  series: [pendientesDias]
                }}
                type="Line"
                options={pendientesOptions}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}></h4>
              <p className={classes.cardCategory}></p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Última semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={handleClose} autoHideDuration={6000}>
            <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                {message}
            </Alert>
      </Snackbar>
      <Loader load={load} />
    </div>
  );
}
