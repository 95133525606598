import Dashboard from "@mui/icons-material/Dashboard";
import Input from "@mui/icons-material/Input";
import People from "@mui/icons-material/People";
import DeveloperBoard from "@mui/icons-material/DeveloperBoard";
import ListAlt from "@mui/icons-material/ListAlt";
import NoteAdd from "@mui/icons-material/NoteAdd";
import TrendingUp from "@mui/icons-material/TrendingUp";
import InsertChartOutlined from "@mui/icons-material/InsertChartOutlined";
import Publish from "@mui/icons-material/Publish";
import AddTaskIcon from '@mui/icons-material/AddTask';
import AccountBox from '@mui/icons-material/AccountBox';
import Ballot from '@mui/icons-material/Ballot';
import AddToQueue from '@mui/icons-material/AddToQueue';

// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import LogoutPage from "views/LoginPage/LogoutPage.jsx";
import Captura from "views/Invex/Captura.jsx";
import Validacion from "views/Invex/Validacion.jsx";
import ReporteCaptura from "views/Invex/ReporteCaptura";
import ReporteCapturaInvex from "views/Invex/ReporteCapturaInvex";
import ReportePendientes from "views/Invex/ReportePendientes";
import ReporteProcesadas from "views/Invex/ReporteProcesadas";
import CargaMasiva from "views/Invex/CargaMasiva";
import Referidos from "views/Invex/Referidos";
import ReporteReferidos from "views/Invex/ReporteReferidos";
import ReporteConversiones from "views/Invex/ReporteConversiones";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    id: 1
  },
  {
    path: "/usuarios",
    name: "Usuarios",
    icon: People,
    component: UserProfile,
    layout: "/admin",
    id: 2
  },
  {
    path: "/capturainvex",
    name: "Captura Invex",
    icon: ListAlt,
    component: Captura,
    layout: "/admin",
    id: 3
  },
  {
    path: "/validacion",
    name: "Validacion",
    icon: NoteAdd,
    component: Validacion,
    layout: "/admin",
    id: 4
  },
  {
    path: "/reporteinvex",
    name: "Reporte Invex",
    icon: InsertChartOutlined,
    component: ReporteCapturaInvex,
    layout: "/admin",
    id: 5
  },
  {
    path: "/cargamasiva",
    name: "Carga Masiva",
    icon: Publish,
    component: CargaMasiva,
    layout: "/admin",
    id: 6
  },
  {
    path: "/reportecaptura",
    name: "Reporte Captura",
    icon: TrendingUp,
    component: ReporteCaptura,
    layout: "/admin",
    id: 7
  },
  {
    path: "/reportependientes",
    name: "Reporte Pendientes",
    icon: DeveloperBoard,
    component: ReportePendientes,
    layout: "/admin",
    id: 8
  },
  {
    path: "/reporteprocesadas",
    name: "Reporte Procesadas",
    icon: AddTaskIcon,
    component: ReporteProcesadas,
    layout: "/admin",
    id: 9
  },
  {
    path: "/referidos",
    name: "Referidos",
    icon: AccountBox,
    component: Referidos,
    layout: "/admin",
    id: 10
  },
  {
    path: "/reportereferidos",
    name: "Reporte Referidos",
    icon: Ballot,
    component: ReporteReferidos,
    layout: "/admin",
    id: 11
  },
  {
    path: "/reporteconversiones",
    name: "Reporte Conversiones",
    icon: AddToQueue,
    component: ReporteConversiones,
    layout: "/admin",
    id: 12
  },
  {
    path: "/logout",
    name: "Cerrar Sesión",
    icon: Input,
    component: LogoutPage,
    layout: "/admin",
    id: 0
  }
];

export default dashboardRoutes;