import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import AddAlert from "@mui/icons-material/AddAlert";
//core components
import Snackbar from "components/Snackbar/Snackbar.js";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@mui/icons-material/Email";
// core components
import Header from "components/Header/Header.jsx";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import loginPageStyle from "assets/jss/material-dashboard-react/views/loginPage.jsx";

require("dotenv").config();

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      pass: "",
      open: false,
      place: "",
      color: "",
      message: ""
    };
  }

  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  getYear() {
    return new Date().getFullYear();
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

  sendSnackbar(mess) {
    this.setState({ open: true, message: mess, place: "br", color: "danger" });
    let that = this;

    setTimeout(function() {
      that.setState({ open: false });
    }, 6000);
  }

  setOpen(op) {
    this.setState({ open: op });
  }

  async Login () {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
            headers: {
                "Content-Type": "application/json"
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Login",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
      this.sendSnackbar("Error de conexión");
    }

    return dataResponse;
  };

  async UsuarioLogin(data, token) {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Usuario/Login",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
      this.sendSnackbar("Error de conexión");
    }

    return dataResponse;
  };

  handleSubmit(event) {
    event.preventDefault();

    let that = this;
    let { email, pass } = this.state;
    
    that.Login()
    .then(result => {
      if(result !== undefined){
        that.UsuarioLogin({ Usuario: email, Clave: pass }, result.token)
        .then((result) => {
          if(result !== undefined){
            let data = result.resultado;

            if(data.Resultado === 1){
              if(localStorage.getItem("userCEX") !== null){
                localStorage.removeItem("userCEX");
              }
              localStorage.setItem("userCEX", JSON.stringify({ email, user: data.Usuario, idRol: data.IdRol, IdUsuario: data.IdUsuario, IdSucursales: data.IdSucursales }));
              that.setState({email: ""});
            }
            else {
              that.sendSnackbar(data.Mensaje);
            }
          }
        }).catch(error => that.sendSnackbar("Error de red"));
      } else {
        that.sendSnackBar("Error de conexión");
    }
    }).catch(error => that.sendSnackbar("Error de conexión"));
  }

  render() {
    const { classes, ...rest } = this.props;

    if (localStorage.getItem("userCEX") !== null) {
      return <Redirect to="/admin" />;
    }

    return (
      <div>
        <Header absolute color="transparent" brand="cex" {...rest} />
        <div
          className={classes.pageHeader}
          style={{
            backgroundColor: "white",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4} lg={4}>
                <Card className={classes[this.state.cardAnimaton]}>
                  <form
                    onSubmit={e => {
                      this.handleSubmit(e);
                    }}
                    className={classes.form}
                  >
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4>Login</h4>
                    </CardHeader>
                    <CardBody>
                      <CustomInput
                        labelText="Usuario"
                        id="email"
                        onBlur={e => {
                          this.handleChange(e);
                        }}
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                      />
                      <CustomInput
                        labelText="Contraseña"
                        id="pass"
                        formControlProps={{
                          fullWidth: true
                        }}
                        onBlur={e => {
                          this.handleChange(e);
                        }}
                        inputProps={{
                          type: "password",
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                              </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off"
                        }}
                      />
                      <div style={{ textAlign: "end" }}>
                        <Button color="primary" type="submit" round>
                          Iniciar sesión
                        </Button>
                      </div>
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <p>© CEX {this.getYear()}</p>
                    </CardFooter>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Snackbar
            place={this.state.place}
            color={this.state.color}
            icon={AddAlert}
            message={this.state.message}
            open={this.state.open}
            closeNotification={() => this.setOpen(false)}
            close
          />
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(loginPageStyle)(LoginPage);
