import React, { useState, forwardRef } from "react";
import { DataGrid } from '@mui/x-data-grid';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";

import Loader from "components/Loader/Loader";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import moment from "moment";
import "moment/locale/es";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

moment.locale("es");

export default function ReporteCapturaInvex() {
  
  const useStyles = makeStyles(theme => ({
    ...dashboardStyle,
    cardTitle: {
      marginTop: "0",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    },
    select: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      color: "#D2D2D2",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "1.42857",
      letterSpacing: "unset",
      "&:before": {
        borderColor: "#D2D2D2",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        letterSpacing: "unset"
      },
      "&:after": {
        borderColor: "#D2D2D2",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        letterSpacing: "unset"
      }
    },
    container: {
      textAlign: "center"
    },
    content: {
      display: "inline-flex",
      padding: "10px",
      alignItems: "center"
    },
    paddingBottomCard: {
      paddingBottom: "40px"
    },
    marginRight: {
      marginRight: "10px"
    },
    marginBottom: {
      marginBottom: "50px"
    },
    formControl: {
      marginTop: theme.spacing(3.5),
      minWidth: "100%"
    },
    formControl2: {
      minWidth: "100%",
      marginTop: theme.spacing(5)
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }));

  const classes = useStyles();

  const [selectFechaInicio, setSelectedFechaInicio] = useState(new Date());
  const [selectFechaFin, setSelectedFechaFin] = useState(new Date());

  const [load, setLoad] = useState(false);

  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [columnReporte] = useState([
    { title: "Referencia", field: "Referencia", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Referencia Invex", field: "ReferenciaInvex", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Nombres", field: "Nombres", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Apellido Paterno", field: "ApellidoPaterno", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Apellido Materno", field: "ApellidoMaterno", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Fecha Nacimiento", field: "FechaNacimiento", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "RFC", field: "RFC", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Correo", field: "Correo", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Celular", field: "Celular", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Telefono Fijo", field: "TelefonoFijo", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Telefono Trabajo", field: "TelefonoTrabajo", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Estado Civil", field: "EstadoCivil", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Grado Estudios", field: "GradoEstudios", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Lugar Nacimiento", field: "LugarNacimiento", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Calle", field: "Calle", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Numero Exterior", field: "NumeroExterior", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Numero Interior", field: "NumeroInterior", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Colonia", field: "Colonia", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Estado", field: "Estado", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Municipio/Alcaldia ", field: "Localidad", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Codigo Postal", field: "CodigoPostal", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Banco", field: "Banco", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Referencia TDC", field: "ReferenciaTDC", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Referencia Credito Hipotecario", field: "ReferenciaCreditoHipotecario", type: "string", headerClassName: 'super-app-theme--header', width: 300 },
    { title: "Referencia Credito Auto", field: "ReferenciaCreditoAuto", type: "string", headerClassName: 'super-app-theme--header', width: 300 },
    { title: "Producto", field: "Producto", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Capturista", field: "Capturista", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Validador", field: "Validador", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Ingreso Mensual", field: "IngresoMensual", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Estatus", field: "Estatus", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Tipo Base", field: "TipoBase", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Fecha Captura", field: "FechaCaptura", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Fecha Higienizacion", field: "FechaSanitizacion", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Folio Fico", field: "FolioFico", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Pendiente", field: "Pendiente", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Comentarios", field: "Comentarios", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Actividad Laboral", field: "ActividadLaboral", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Codigo Postal Laboral", field: "CodigoPostalLaboral", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Dependientes Economicos", field: "DependientesEconomicos", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Fecha Capturista", field: "FechaCapturista", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Fecha Validador", field: "FechaValidador", type: "string", headerClassName: 'super-app-theme--header', width: 200 }
  ]);
  const [reporte, setReporte] = useState([]);

  const sendSnackBar = (pmessage, pcolor) => {
    setOpenSnackbar(true);
    setColor(pcolor);
    setMessage(pmessage);
  };
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const convertToCSV = (objArray) => {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
        let line = "";

        for (let index in array[i]) {
            if (line !== "") line += ",";
            line += array[i][index];
        }

        str += line + "\r\n";
    }

    return str;
  };

  const exportCSVFile = (headers, items, fileName) => {
    if (headers) {
        items.unshift(headers);
    }

    const jsonObject = JSON.stringify(items);
    const csv = convertToCSV(jsonObject);
    const exportName = fileName + ".csv" || "export.csv";
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportName);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportName);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
  };

  const exportData = (JSONData) => {
    let arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    let reportTitle = "Reporte_Invex";
    let headers = {};

    arrData = arrData.map(item => { 
      return { 
        Referencia: "'" + item.Referencia.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""), 
        ReferenciaInvex: "'" + item.ReferenciaInvex.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Nombres: item.Nombres.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        ApellidoPaterno: item.ApellidoPaterno.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        ApellidoMaterno: item.ApellidoMaterno.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        FechaNacimiento: item.FechaNacimiento.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        RFC: item.RFC.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Correo: item.Correo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Celular: item.Celular.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        TelefonoFijo: item.TelefonoFijo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        TelefonoTrabajo: item.TelefonoTrabajo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        EstadoCivil: item.EstadoCivil.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        GradoEstudios: item.GradoEstudios.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        LugarNacimiento: item.LugarNacimiento.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Calle: item.Calle.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        NumeroExterior: item.NumeroExterior.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        NumeroInterior: item.NumeroInterior.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Colonia: item.Colonia.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Estado: item.Estado.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Localidad: item.Localidad.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        CodigoPostal: item.CodigoPostal.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Banco: item.Banco.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        ReferenciaTDC: item.ReferenciaTDC.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        ReferenciaCreditoHipotecario: item.ReferenciaCreditoHipotecario.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        ReferenciaCreditoAuto: item.ReferenciaCreditoAuto.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Producto: item.Producto.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Capturista: item.Capturista.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Validador: item.Validador.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        IngresoMensual: item.IngresoMensual,
        Estatus: item.Estatus.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        TipoBase: item.TipoBase.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        FechaCaptura: item.FechaCaptura.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        FechaSanitizacion: item.FechaSanitizacion.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        FolioFico: item.FolioFico.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Pendiente: item.Pendiente.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        Comentarios: item.Comentarios.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        ActividadLaboral: item.ActividadLaboral.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        CodigoPostalLaboral: item.CodigoPostalLaboral.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        DependientesEconomicos: item.DependientesEconomicos.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        FechaCapturista: item.FechaCapturista.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", ""),
        FechaValidador: item.FechaValidador.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "")
      };
    });

    headers = {
        Referencia: "Referencia",
        ReferenciaInvex: "Referencia Invex",
        Nombres: "Nombres",
        ApellidoPaterno: "Apellido Paterno",
        ApellidoMaterno: "Apellido Materno",
        FechaNacimiento: "Fecha Nacimiento",
        RFC: "RFC",
        Correo: "Correo",
        Celular: "Celular",
        TelefonoFijo: "Telefono Fijo",
        TelefonoTrabajo: "Telefono Trabajo",
        EstadoCivil: "Estado Civil",
        GradoEstudios: "Grado Estudios",
        LugarNacimiento: "Lugar Nacimiento",
        Calle: "Calle",
        NumeroExterior: "Numero Exterior",
        NumeroInterior: "Numero Interior",
        Colonia: "Colonia",
        Estado: "Estado",
        Localidad: "Localidad",
        CodigoPostal: "Codigo Postal",
        Banco: "Banco",
        ReferenciaTDC: "Referencia TDC",
        ReferenciaCreditoHipotecario: "Referencia Credito Hipotecario",
        ReferenciaCreditoAuto: "Referencia Credito Auto",
        Producto: "Producto",
        Capturista: "Capturista",
        Validador: "Validador",
        IngresoMensual: "Ingreso Mensual",
        Estatus: "Estatus",
        TipoBase: "TipoBase",
        FechaCaptura: "FechaCaptura",
        FechaSanitizacion: "FechaSanitizacion",
        FolioFico: "FolioFico",
        Pendiente: "Pendiente",
        Comentarios: "Comentarios",
        ActividadLaboral: "ActividadLaboral",
        CodigoPostalLaboral: "CodigoPostalLaboral",
        DependientesEconomicos: "DependientesEconomicos",
        FechaCapturista: "FechaCapturista",
        FechaValidador: "FechaValidador"
    };
    
    exportCSVFile(headers, arrData, reportTitle);
  };

  const GetIdSucursal = () => {
    return JSON.parse(localStorage.getItem('userCEX')).IdSucursales;
  };

  const handeSubmit = event => {
    event.preventDefault();
    try{
        setLoad(true);

        let fechaInicio = moment(selectFechaInicio);
        fechaInicio.set({h: 0, m: 0, s: 0});

        let fechaFin = moment(selectFechaFin);
        fechaFin.set({h: 23, m: 59, s: 59});

        Login()
        .then(result => {
            if (result !== undefined) {
                let body = {
                    FechaInicio: fechaInicio.format("YYYY/MM/DD HH:mm:ss"),
                    FechaFin: fechaFin.format("YYYY/MM/DD HH:mm:ss"),
                    IdSucursales: GetIdSucursal()
                };

                ReporteCapturaInvex(body, result.token)
                .then(result => {
                      let capturas = result.capturas.map((element) => {
                        element.FechaNacimiento = element.FechaNacimiento === "" ? "" : moment(moment(element.FechaNacimiento, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                        element.FechaCaptura = element.FechaCaptura === "" ? "" : moment(moment(element.FechaCaptura, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                        element.FechaHigienizacion = element.FechaHigienizacion === "" ? "" : moment(moment(element.FechaHigienizacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                        element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                        element.FechaValidador = element.FechaValidador === "" ? "" : moment(moment(element.FechaValidador, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                        element.ReferenciaCreditoHipotecario = element.ReferenciaCreditoHipotecario === "1" ? "Si" : "No";
                        element.ReferenciaCreditoAuto = element.ReferenciaCreditoAuto === "1" ? "Si" : "No";
                        element.Pendiente = element.Pendiente === "1" ? "Si" : "No";
                        element.id = element.ReferenciaInvex;

                        return element;
                      });
                    setReporte(capturas);
                    setLoad(false);
                });
            } else {
                sendSnackBar("Error de conexión", "error");
                setLoad(false);
            }
        }).catch(error => sendSnackBar("Error de conexión", "error"));
    } catch(error){
        sendSnackBar("Error de conexión", "error");
        setLoad(false);
    }
  };

  const ReporteCapturaInvex = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ReporteCapturaInvex",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const Login = async () => {
      let dataResponse;
      try {
          let header = {
              method: "POST",
              body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
              headers: {
                  "Content-Type": "application/json"
              }
          };

          let response = await fetch(
              process.env.REACT_APP_URL_APICORE + "Login",
              header
          );

          dataResponse = await response.json();
      } catch (error) {
          sendSnackBar("Error de conexión", "error");
      }

      return dataResponse;
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid className={classes.marginBottom}>
          <Grid container className={classes.paddingBottomCard}>
            <GridItem xs={12} sm={12} md={4}>
              <FormControl className={classes.formControl}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    format="yyyy/MM/dd"
                    label="Fecha inicio"
                    value={selectFechaInicio}
                    onChange={(date) => setSelectedFechaInicio(date)}
                    slotProps={{ textField: { fullWidth: true } }}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </DemoContainer>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <FormControl className={classes.formControl}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Fecha Final"
                    format="yyyy/MM/dd"
                    value={selectFechaFin}
                    onChange={(date) => setSelectedFechaFin(date)}
                    slotProps={{ textField: { fullWidth: true } }}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </DemoContainer>
              </FormControl>
            </GridItem>
          </Grid>
          <Grid container spacing={3} style={{marginLeft: "1px"}}>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                onClick={e => {
                  handeSubmit(e);
                }}
                color="info"
              >
                Buscar
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                onClick={e => {
                  exportData(reporte);
                }}
                color="info"
              >
                Descargar reporte
              </Button>
            </GridItem>
          </Grid>
        </Grid>
      </LocalizationProvider>
      <DataGrid
        rows={reporte}
        columns={columnReporte}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          '& .super-app-theme--header': {
            backgroundColor: 'primary.light',
          },
        }}
      />
        <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={handleClose} autoHideDuration={6000}>
            <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
        <Loader load={load} />
    </div>
  );
}